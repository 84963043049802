import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Card, Table, Flex, Dropdown } from 'antd'
import { ModuleTopHeading, MyInputnoLabel } from '../../components';
import { FilterOutlined } from '@ant-design/icons';

const { Text } = Typography

const Transaction = () => {

    const [form] = Form.useForm();
  
  
    const items = [
        {
            label: <a href="#" className='text-white'>A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#" className='text-white'>Z-A</a>,
            key: '1',
        },
    ];

    const columns = [
        {
            title: <Text className='text-white'>Description</Text>,
            dataIndex: 'desc',
        },
        {
            title: <Text className='text-white'>Type</Text>,
            dataIndex: 'type',
            width:300
        },
        {
            title: <Text className='text-white'>Amount</Text>,
            dataIndex: 'amount',
            width:300
        },
        {
            title: <Text className='text-white'>Time stamp</Text>,
            dataIndex: 'timeStamp',
            width:250
        },
    ];

    const data = [
        {
            key: '1',
            desc: <Text className='text-white'>Abc</Text>,
            type: <Text className='text-white'>Win</Text>,
            amount: <Text className='text-white'>1200$</Text>,
            timeStamp: <Text className='text-white'>12/12/2023 12:00 AM</Text>,
        },
        {
            key: '2',
            desc: <Text className='text-white'>Abc</Text>,
            type: <Text className='text-white'>Loss</Text>,
            amount: <Text className='text-white'>900$</Text>,
            timeStamp: <Text className='text-white'>12/12/2023 10:00 PM</Text>,
        },
        {
            key: '3',
            desc: <Text className='text-white'>Abc</Text>,
            type: <Text className='text-white'>Rebate</Text>,
            amount: <Text className='text-white'>1500$</Text>,
            timeStamp: <Text className='text-white'>01/07/2023 07:00 PM</Text>,
        },
        {
            key: '2',
            desc: <Text className='text-white'>Abc</Text>,
            type: <Text className='text-white'>Withdraw</Text>,
            amount: <Text className='text-white'>900$</Text>,
            timeStamp: <Text className='text-white'>21/04/2024 04:00 PM</Text>,
        },
        {
            key: '3',
            desc: <Text className='text-white'>Abc</Text>,
            type: <Text className='text-white'>Deposit</Text>,
            amount: <Text className='text-white'>1500$</Text>,
            timeStamp: <Text className='text-white'>18/06/2024 03:00 PM</Text>,
        },
    ];
  
    return (
        <div>
            <Card className='card-bg rounded border0'>
                <Row gutter={[24,24]}>
                    <Col xs={24} sm={24} md={12} lg={18}>
                        <ModuleTopHeading
                            name='Transaction history'
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Flex justify='end' gap={10}>
                                <MyInputnoLabel
                                    name='name'
                                    label='Search'
                                    placeholder='Search here ...'
                                    value={form.getFieldValue("name") || ''} 
                                    className='w-100'
                                    onChange={(e)=>e.target.value}
                                />
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    mode
                                    trigger={['click']}
                                    arrow
                                    icon={<FilterOutlined />}
                                >
                                    <Button
                                        icon={<FilterOutlined />}
                                        className='bg-main border0 text-white btn-filter'
                                    >
                                        Filter
                                    </Button>
                                </Dropdown>
                            </Flex>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-gradient border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export {Transaction}